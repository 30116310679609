

h2 {
  font-weight: 600 !important;
}

h3 {
  margin-top: 30px;
}


/* table stuff */
.niceTable          {
  overflow: auto; 
  overflow-x: scroll;
  font-size: 13px;
}
.niceTable thead { 
  position: sticky; top: 0; z-index: 1; 
}
table  { border-collapse: collapse;}
th, td { 
  padding: 4px 4px;
  /* border-left: 1px solid #eee; */
}
th { 
  background-color: rgb(183, 212, 252);
}
tr {
  /* border-bottom: 1px solid #eee; */
}

tr:nth-child(even) {background-color: #f2f2f2;}


.niceTable table td:first-child {
  position: sticky;
  left: 0;
  background-color: rgb(228, 237, 250);
}

.headerLink {
  cursor: pointer;
  text-decoration: underline;
  color: var(--ion-color-primary);
}

#searchResult {
  background-color: yellow;
}

ion-slides {
  height: 100%
}

.popover-content {
  width: 300px !important;
}

.swiper-pagination-bullet-active {
  background: white;
}

ion-avatar {
  margin-right: 10px;
  margin-left: -10px;
}

.swiper-pagination {
  bottom: 15px !important;
}